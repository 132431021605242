import React from 'react'
import { languageList } from '../../../config'

const JobValue = ({ label, value }) => (
    <div className="">
        <span className="fw-bold me-2">{ label }:</span>
        <span>{ value || ''}</span>
    </div>
)

const dateFormat = val => {
    if (val) {
        const parts = val.split('-').map(s => parseInt(s))
        val = new Date(parts[0], parts[1] - 1, parts[2]).toLocaleDateString()
    }
    return val
}

const languageFormat = val => val ? languageList.find(l => l[0] == val)?.[1] : ''

const JobSummary = ({ jobData }) => (
    <>
        <JobValue label="Reference Number" value={jobData.refId} />
        <JobValue label="Source Language" value={ languageFormat(jobData.sourceLanguage) } />
        <JobValue label="Target Language" value={ languageFormat(jobData.targetLanguage) } />
        <JobValue label="File to Translate" value={jobData.file?.name  || jobData.fileName || jobData.file} />
        <JobValue label="Instructions" value={jobData.instructions} />
    </>
)

export default JobSummary
