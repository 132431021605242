import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useDrop } from 'react-dnd'
import { NativeTypes } from 'react-dnd-html5-backend'
import classNames from 'classnames'

import JobSummary from './JobSummary'
import Spinner from './Spinner'
import TranslationContext from './TranslationContext'
import { axios, languageList } from '../../../config'
import { useJobData } from './server'

const FormContext = React.createContext({})

const Input = ({ name, type, size, label, children, ...props }) => {
    const { jobData, updateField } = useContext(FormContext)
    return (
        <div className={`col-${size}`}>
            <label className="form-label">
                { props.required && <span className="text-danger me-1">*</span>}
                { label }
            </label>
            { type === 'select' ? (
                <select
                    className="form-select"
                    aria-label={label}
                    value={jobData[name] || ''}
                    onChange={ev => updateField(name, ev.target.value)}
                    { ...props }
                    >
                    { children }
                </select>
            ) : type === 'textarea' ? (
                <textarea
                    className="form-control"
                    aria-label={label}
                    value={jobData[name] || ''}
                    onChange={ev => updateField(name, ev.target.value)}
                    { ...props }
                    />
            ) : (
                <input
                    className="form-control"
                    aria-label={label}
                    type={type}
                    value={jobData[name] || ''}
                    onChange={ev => updateField(name, ev.target.value)}
                    { ...props }
                    />
            )}
        </div>
    )
}

const FileSelect = ({ name, size, label }) => {
    const { jobData, updateField } = useContext(FormContext)
    const fileInputRef = useRef()
	const [{ canDrop, isOver }, drop] = useDrop(
		() => ({
			accept: [NativeTypes.FILE],
			drop(item) {
                const file = item.files?.[0]
                if (file) {
				    updateField(name, file)
                }
			},
			collect: (monitor) => ({
    			isOver: monitor.isOver(),
	    		canDrop: monitor.canDrop(),
            }),
		}),
		[ name, updateField ],
	)

	const isActive = canDrop && isOver
    const currFile = jobData[name]
    let fileName = 'Click to select or drop here'
    if (currFile instanceof File) {
        fileName = currFile.name
    }
    else if (typeof currFile === 'string') {
        fileName  =  jobData.fileName || currFile
    }

	return (
        <div className={`col-${size}`}>
            <label className="form-label">{ label }</label>
            <input
                ref={fileInputRef}
                className="d-none"
                type="file"
                onChange={(ev) => updateField(name, ev.target.files[0])}
                />
            <div
                ref={drop}
                className={classNames({ 'file-drop': true, 'active': isActive })}
                onClick={() => fileInputRef.current.click()}
                >
			    { isActive ? 'Release to drop' : fileName }
		    </div>
        </div>
	)
}

const JobForm = ({ jobData, updateField }) => (
    <FormContext.Provider value={{ jobData, updateField }}>
        <Input name="refId" size="md-6" label="Reference Number" required type="text" />
        <Input name="sourceLanguage" size="md-3" label="Source Language" required type="select"
         value ={jobData.sourceLanguage} 
         onChange={(e) => updateField("sourceLanguage", e.target.value)}>
            <option value="Select a Language"></option> 
            { languageList.map(([ code, name ]) => (
                <option key={code} value={code}>{ name }</option>
            ))}
        </Input>
        <Input name="targetLanguage" size="md-3" label="Target Language" required type="select"
       value ={jobData.targetLanguage} 
       onChange={(e) => updateField("targetLanguage", e.target.value)}>
         <option value="Select a Language"></option> 
            { languageList.map(([ code, name ]) => (
                <option key={code} value={code}>{ name }</option>
            ))}
        </Input>
        <FileSelect name="file" size="md-6" label="Upload File to Translate" />
        <Input name="instructions" size="md-6" label="Instructions" type="textarea" />
    </FormContext.Provider>
)

const DEFAULT_JOB_DATA = { sourceLanguage: 'en', targetLanguage: 'es'}

const JobEditPage = ({ }) => {
    const navigate = useNavigate()
    const { bearerToken, jobId } = useContext(TranslationContext)
    const initialJobData = useJobData()
    const [ jobData, setJobData ] = useState(DEFAULT_JOB_DATA)
    const [ errorMsg, setErrorMsg ] = useState(null)
    const [ confirming, setConfirming ] = useState(false)
    const [ submitting, setSubmitting ] = useState(false)

    useEffect(() => {
        setJobData(initialJobData)
    }, [ initialJobData, setJobData ])

    const updateField = (name, value) => setJobData({ ...jobData, [name]: value })

    const onSubmit = (ev) => {
        ev.preventDefault()

        setErrorMsg(null)

        if (confirming) {
            setSubmitting(true)

            const data = { ...jobData, jobId }
            if (data.file instanceof File) {
                data.fileData = data.file // backend has scrict type checking, so put upload file in "fileData" property
                delete data.file
            }

            axios.put(`/translation/job/${jobId}`, data, {
                headers: {
                    'Authorization': bearerToken,
                    'Content-Type': 'multipart/form-data',
                }
            }).then(() => {
                navigate('/translation/status')
            }).catch(ex => {
                console.error('error', bearerToken, ex)
            }).finally(() => {
                setSubmitting(false)
            })
        }
        else {
            if (!jobData.file) {
                setErrorMsg('Please select a file to translate')
                return
            }

            setConfirming(true)
        }
    }

    const onBack = () => {
        if (confirming) {
            setConfirming(false)
        }
        else {
            setJobData(initialJobData)
            setErrorMsg(null)
        }
    }

    return (
        <div className="JobEditPage">
            <h1 className="mb-5">New Translation Request</h1>

            { jobData ? (
                <form className="row g-3" onSubmit={onSubmit}>
                    { confirming && <h3 className="mb-4">Request Summary</h3> }

                    { confirming ? (
                        <JobSummary jobData={jobData} />
                    ) : (jobData && (
                        <JobForm jobData={jobData} updateField={updateField} />
                    ))}

                    { errorMsg && <div className="alert alert-danger">{ errorMsg }</div> }

                    <div className="col-12 d-flex flex-row-reverse">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            disabled={submitting}
                            >
                            { confirming ? 'Submit' : 'Next' }
                            { submitting && <div className="ms-1 spinner-border spinner-border-sm" role="status" /> }
                        </button>
                        <button
                            type="button"
                            className="btn btn-outline-primary me-2"
                            onClick={onBack}
                            disabled={submitting}
                            >
                            { confirming ? 'Back' : 'Reset' }
                        </button>
                    </div>
                </form>
            ) : (
                <Spinner />
            )}
        </div>
    )
}

export default JobEditPage
